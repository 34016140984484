// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-pointer-podcast-tsx": () => import("./../../../src/pages/articles/pointer-podcast.tsx" /* webpackChunkName: "component---src-pages-articles-pointer-podcast-tsx" */),
  "component---src-pages-blog-how-we-built-iterspace-tsx": () => import("./../../../src/pages/blog/how-we-built-iterspace.tsx" /* webpackChunkName: "component---src-pages-blog-how-we-built-iterspace-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */)
}

